<div mat-dialog-title>
  <div class="justify-between">
    <h2 class="fw-600">
      {{ text.title }}
    </h2>
    <div class="c-pointer" mat-dialog-close>
      <img [src]="icon.closeBlack.src" [alt]="icon.closeBlack.alt" />
    </div>
  </div>
</div>
<div class="mat-dialog-roam-content">
  <div [innerHTML]="text.body"></div>
</div>
<mat-dialog-actions
  class="mat-dialog-roam-action gap-10"
  [style.justify-content]="data.confirmation.btnLayout + '!important'"
>
  <button mat-button mat-dialog-close class="btn-close c-pointer">
    <span class="text-underline fw-400" [class]="data.confirmation.btnCancelClass">
      {{ text.btnCancel }}
    </span>
  </button>
  <app-roam-button
    [class]="'btn btn-primary bold pr-24 pl-24 h-45'"
    [label]="text.btnSubmit"
    (onClick)="submit()"
  >
  </app-roam-button>
</mat-dialog-actions>
